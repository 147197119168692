<!-- 积分配置 -->
<template>
    <div class="point_container">

        <el-form ref="form" :model="form" :rules="rules">
            <el-row :gutter="10">
                <el-col :span="6">
                    <el-form-item label="积分消费比" label-width="200px">
                        <el-switch v-model="form.pointsRatioEnable"></el-switch>
                    </el-form-item>
                </el-col>
                <el-col :span="14">
                    <el-form-item label="">
                        <el-input type="number" min="0" v-model="form.pointsRatio" @keydown.native="(e) =>
        (e.returnValue = ['e', 'E', '+', '-'].includes(
            e.key
        )
            ? false
            : e.returnValue)
            "></el-input>
                    </el-form-item>
                    <div class="tips">消费1元获得积分数</div>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :span="6">
                    <el-form-item label="连续签到赠送积分" label-width="200px">
                        <el-switch v-model="form.checkInEnable"></el-switch>
                    </el-form-item>
                </el-col>
                <el-col :span="14">
                    <el-form-item label="" prop="checkIn">
                        <el-input v-model="form.checkIn"></el-input>
                    </el-form-item>
                    <div class="tips">连续7天签到，请填入连续1-7天内每日的积分数以英文分号分隔，如1;2;3;4;5;6;7以最后一个积分数作为连续签满后的基础赠送积分</div>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :span="6">
                    <el-form-item label="积分有效期配置" label-width="200px">
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item>
                        <el-button type="primary" plain @click="addRules('timeform')">添加过期规则</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10" type="flex" justify="center" style="margin-bottom: 20px;">
                <el-col :span="12">
                    <el-table :data="form.expireRuleVoList" border style="width: 100%">
                        <el-table-column  label="积分获得时间">
                            <template slot-scope="scope">
                               <span>{{ scope.row.obtainStartDate }} 至 {{ scope.row.obtainEndDate}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="expDate" label="积分过期时间">
                        </el-table-column>
                        <el-table-column  label="操作">
                            <template slot-scope="scope" >
                                <el-button v-if="scope.row.ruleId==null" type="text" size="medium" @click.stop="del(scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                        
                    </el-table>
                </el-col>
            </el-row>

            <el-row type="flex" justify="center">
                <el-col :span="6">
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">保存</el-button>
                    </el-form-item>
                </el-col>
            </el-row>

        </el-form>
        <el-dialog title="添加过期规则" :visible.sync="dialogVisible" width="50%" @close="handleClose('timeform')">
            <el-form ref="timeform" :model="timeForm" :rules="rules" label-width="120px">

                <el-form-item label="积分获得时间" prop="obtainDate">

                    <el-date-picker v-model="timeForm.obtainDate" :value-format="'yyyy-MM-dd'" type="daterange"
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="obtainDateChange">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="积分过期时间" prop="expDate">
                    <el-date-picker type="date" placeholder="过期日期" v-model="timeForm.expDate"
                        :value-format="'yyyy-MM-dd'" style="width: 100%;"
                        :picker-options="pickerOptions"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('timeform')">保存</el-button>

                </el-form-item>
                <span style="color: red;font-size: 12px;">保存后不可修改或删除，请谨慎操作！</span>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
import { rules } from "@/db/rules";
import { editConfig, getConfig } from "@/api/point/point";
export default {
    name: 'config',
    data() {
        return {
            rules,
            dialogVisible: false,
            form: {
                configId:'',
                pointsRatio:0,
                checkIn:'',
                checkInEnable: false,
                pointsRatioEnable:false,
                expireRuleVoList: []
            },
            timeForm: {
                obtainDate: '',
                expDate: '',
                obtainEndDate:'',
                obtainStartDate:''
            },
            // 限制积分过期日期只能在获得时间后面
            pickerOptions: {
                disabledDate: (time) => {
                    return time.getTime() < new Date(this.timeForm.obtainDate[1]).getTime();
                }
            },

        }
    },
    created() {
        this.init()
    },
    methods: {
        init(){
            getConfig().then(res=>{
                this.form = res.data
            })
        },
        del(row){
            let index=this.form.expireRuleVoList.findIndex(item=>item.obtainStartDate==row.obtainStartDate)
            this.form.expireRuleVoList.splice(index,1)
        },
    
        // reset() {
        //     this.timeForm.obtainDate = ''
        //     this.timeForm.expDate = ''
        // },
        addRules(formName) {
            this.dialogVisible = true
        },
        handleClose(formName) {
            this.dialogVisible = false
            this.$refs[formName].resetFields();
        },
       
        // 判断时间重叠
        isOverlapping(currentInterval, intervals) {
            for (let i = 0; i < intervals.length; i++) {
                const interval = intervals[i];
                if (currentInterval[0] <= interval[1] && currentInterval[1] >= interval[0]) {
                    return true;
                }
            }
            return false;
        },
        // 获得时间改变时触发
        obtainDateChange(e) {
            let currentInterval = [new Date(e[0]).getTime(), new Date(e[1]).getTime()];
            if(this.form.expireRuleVoList.length>0){
                let intervals = this.form.expireRuleVoList.map(item => [new Date(item.obtainStartDate).getTime(), new Date(item.obtainEndDate).getTime()]);
                if (this.isOverlapping(currentInterval, intervals)) {
                    this.$message.error('积分获得时间重复');
                    this.timeForm.obtainDate = '';
                    this.timeForm.obtainStartDate = '';
                    this.timeForm.obtainEndDate = '';
                }
            }
        },
        // 过期时间改变时触发
        expDateChange(e) {
                
            
            let intervals = this.form.expireRuleVoList.map(item => [new Date(item.obtainStartDate).getTime(), new Date(item.obtainEndDate).getTime()]);
            if (this.isOverlapping(currentInterval, intervals)) {
                this.$message.error('积分获得时间重复');
                this.timeForm.obtainDate = '';
                this.timeForm.obtainStartDate = '';
                this.timeForm.obtainEndDate = '';
            }
        },


        submitForm(formName) {
            console.log(this.form)
            console.log(this.timeForm)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$confirm('保存后不可修改或删除, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        // this.timeForm.obtainDate = this.timeForm.obtainDate[0] + '至' + this.timeForm.obtainDate[1]
                        this.timeForm.obtainStartDate= this.timeForm.obtainDate[0]
                        this.timeForm.obtainEndDate= this.timeForm.obtainDate[1]
                        this.form.expireRuleVoList.push({
                            obtainDate: this.timeForm.obtainDate,
                            obtainStartDate: this.timeForm.obtainStartDate,
                            obtainEndDate: this.timeForm.obtainEndDate,
                            expDate: this.timeForm.expDate
                        })
                        this.dialogVisible = false
                    }).catch(err => {
                        console.log(err)

                        this.$message({
                            type: 'info',
                            message: '已取消'
                        });
                    });
                } else {
                    return false;
                }
            });
        },
        onSubmit() {
            editConfig(this.form).then(res => {
                this.init()
                this.$message({
                    message: res.message,
                    type: 'success'})
                
            })
        },
       
    }
}
</script>
<style lang="scss" scoped>
.point_container {
    padding: 20px;

    .tips {
        font-size: 12px;
        color: red;
        margin-bottom: 20px;
    }
}
</style>